* {
  font-family: 'Poppins', sans-serif
}
body {
  background: #F4F4F4 !important;
  width: 100%;
  min-height: 100vh;
}

input.form-control, textarea.form-control {
  &:focus {
    box-shadow: none;
    outline: 0;
  }
  font-size: 15px;
  font-weight: 300;
}

.btn:focus, .btn:active, .btn.focus, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}