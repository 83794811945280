@import '../../sass/index.scss';

.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  .foot {
    font-size: 12px;
    margin-top: 15px;
    font-weight: 300;
  }
  .main-form {
    width: 27%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    @media (max-width: 768px) {
      width: 60%;
    }
    @media (max-width: 414px) {
      width: 90%;
    }
    p {
      font-size: 13px;
      color: #666;
    }
    .header-form {
      border-bottom: 1.5px solid #ccc;
      padding-top: 10px;
      padding-bottom: 10px;
      h1 {
        font-size: 21px;
        font-weight: 400;
        color: #333
      }
    }

    .body {

      .nombre-form {
        align-items: center;
        img {
          width: 23px;
          height: 23px;
          margin-right: 10px;
        }
        h3 {
          font-size: 17px;
          font-weight: 300;
          margin: 10px 0;
          color: #333
        }
      }
      
      form {
        input {
          margin-bottom: 10px;
          font-size: 13px;
          padding: 20px 10px;
        }
        .error {
          font-size: 13px;
          border-radius: 0;
          border-left: 3px solid #721c24;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          }
        .btn-submit {
          background-color: $primary !important;
          color: #fff;
          margin-top: 15px;
        }

        .spinner-border {
          width: 20px;
          height: 20px;
          margin: 1px;
          margin-right: 10px;
        }
      }
    }
  }
}