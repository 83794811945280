@import '../../sass/index.scss';

.formulario-encuesta {
  background-image: url('/assets/images/fondo_form.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  // justify-content: center;
  
  
  .main-encuesta {
    margin-top: 50px;
    width: 60%;
    color: #fff;
    @media (max-width: 414px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      margin-top: 0;
    }
    
    @media (max-width: 852px) {
      width: 100%;
    }
    .header-encuesta {
      background-color: $primary;
      padding: 60px 40px 30px;
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 200;
      }
      p {
        margin-top: 30px;
        font-weight: 200;
        font-size: 14px;
      }
    }
    .body-encuesta {
      .mensaje-discente {
        font-size: 13px;
        font-weight: 300;
        margin-top: -15px;
        margin-bottom: 35px !important;
        @media (max-width: 776px) {
          margin-top: 10px;
        }
        .text-required::before {
          content: '*';
          color: red;
          font-size: 18px;
          margin-right: 5px;
        }
      }
      color: #333;
      .botones {
        button {
          background-color: $primary;
          border: 1px solid $primary;
          padding: 13px 80px;
          font-size: 15px;
          font-weight: 300;
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;

          &:hover {
            background-color: rgb(30, 42, 71);
            border: 1px solid rgb(30, 42, 71);
          }
          }
      }
    }
    .foot {
      width: 100%;
      padding: 20px ;
      text-align: center;
      background-color: rgba( 255, 255, 255, .5);
    }
  }
}
