@import '../../sass/index.scss';

.lista-preguntas {
  .pregunta {
    margin-bottom: 50px;
    // padding: 20px;
    p {
      font-size: 17px;
      font-weight: 300;
      @media (max-width: 414px) {
        font-size: 15px;
      }
    }
    p.descripcion {
      font-size: 13px;
      margin-left: 20px;
      font-weight: 400;
    }
    p.obligacion::after {
      content: '*';
      color: #AE1515;
      margin-left: 5px;
    }
    
    .area-respuesta {
      padding-left: 25px;
    }
      textarea {
        font-size: 13px;
      }
    
  }
}