@import '../../sass/index.scss';

.exito-envio {
  background-image: url('/assets/images/fondo_form.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
 
 
  .main-mensaje {
    width: 60%;
    position: relative;
    color: #fff;
    margin-top: 50px;
    @media (max-width: 414px) {
      width: 100%;
    }
    .header-encuesta {
      background-color: $primary;
      padding: 60px 40px 30px;
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 200;
      }
      p {
        margin-top: 30px;
        font-weight: 200;
        font-size: 14px;
      }
    }

    .body-mensaje {
      margin-top: 50px;
      &__mensaje {
        .logo {
          display: flex;
          align-items: center;
          flex-direction: column;
          color: #333;
          img {
            margin-right: 15px;
          }
          h5 {
            font-size: 40px;
            font-weight: 200;
            margin-top: 40px;
          }
        }
        p {
          color: #333;
        }
      }
    }
  }
  .foot {
    width: 60%;
    padding: 20px ;
    text-align: center;
    background-color: rgba( 255, 255, 255, .5);
  }
}